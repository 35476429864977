export enum EnumRankingType {
    Performance = 0,
    MaxLoss = 2,
    InvestedCapital = 3,
    SharpRatio = 4,
    RankingPlace = 5,
    LiquidationFigure = 6,
    TradingVolume = 7,
    Date = 8,
    Reservations = 9,
    Volatility = 10,
}

export interface IBaseRanking {
    label: string;
    value: number | null;
}

export interface IPerformance extends IBaseRanking {
    type: EnumRankingType.Performance;
    creationDate?: Date;
}

export interface IMaxLoss extends IBaseRanking {
    type: EnumRankingType.MaxLoss;
}

export interface IInvestedCapital extends IBaseRanking {
    type: EnumRankingType.InvestedCapital;
}

export interface ISharpRatio extends IBaseRanking {
    type: EnumRankingType.SharpRatio;
}

export interface ILiquidationFigure extends IBaseRanking {
    type: EnumRankingType.LiquidationFigure;
}

export interface ITradingVolume extends IBaseRanking {
    type: EnumRankingType.TradingVolume;
}

export interface IRankingPlace extends IBaseRanking {
    type: EnumRankingType.RankingPlace;
    place: number;
}

export interface IDate extends IBaseRanking {
    type: EnumRankingType.Date;
    date?: Date;
}

export interface IReservations extends IBaseRanking {
    type: EnumRankingType.Reservations;
}

export interface IVolatility extends IBaseRanking {
    type: EnumRankingType.Volatility;
}

export type IRankingData =
    | IPerformance
    | IMaxLoss
    | IInvestedCapital
    | ISharpRatio
    | ILiquidationFigure
    | ITradingVolume
    | IRankingPlace
    | IDate
    | IReservations
    | IVolatility;
