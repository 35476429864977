import { Box, Text } from "@chakra-ui/react";
import { useTranslation } from "next-i18next";
import { useNumberFormatter } from "src/hooks/formatters/use-number-formatter";
import Typography from "src/theme/typography";
import { getPerformanceColor } from "src/utils/color-util";
import { isNullOrUndefined } from "src/utils/common-util";
import { IRanking } from "./ranking";
import { RANKING_VALUE_TEST_ID } from "./ranking-value.test-ids";
import { EnumRankingType } from "./types";

const sizePropsMap = {
    xs: { fontSize: "sm" },
    sm: { fontSize: "xl" },
    md: { fontSize: "2xl" },
    lg: { fontSize: "4xl" },
};

export type IRankingValueSize = keyof typeof sizePropsMap;

interface IRankingValue {
    fontWeight?: keyof (typeof Typography)["fontWeights"];
    hidePoints?: boolean;
    value?: string;
}

export const RankingValue = ({ fontWeight, hidePoints = false, size, data, fractionDigits, value }: IRanking & IRankingValue) => {
    const { t } = useTranslation("common");
    const { formatMoney, formatNumber, formatOrdinals, formatPerformance, formatVolatility } = useNumberFormatter();

    if (data.type === EnumRankingType.RankingPlace) {
        const valueDisplay = isNullOrUndefined(data.value) ? "-" : formatNumber(data.value, fractionDigits ?? 0);
        const placeValue = data.place ? `${formatOrdinals(data.place)} ${t("place")}` : "-";

        return (
            <Box {...sizePropsMap[size]} noOfLines={1} wordBreak="break-all" textAlign="right" display="block" data-test-id={RANKING_VALUE_TEST_ID}>
                <Text fontWeight={fontWeight ?? "semibold"}>{placeValue}</Text>
                {!hidePoints && <Text fontWeight="normal">{`${valueDisplay} ${t("points")}`}</Text>}
            </Box>
        );
    }

    let valueDisplay = "-";
    let color: string | undefined;

    if (data.value !== null && !value) {
        switch (data.type) {
            case EnumRankingType.InvestedCapital:
                valueDisplay = formatMoney(data.value, t);
                break;
            case EnumRankingType.MaxLoss:
                valueDisplay = formatPerformance(data.value, fractionDigits ?? 1);
                break;
            case EnumRankingType.Volatility:
                valueDisplay = formatVolatility(data.value, fractionDigits ?? 1);
                break;
            case EnumRankingType.Performance:
                valueDisplay = formatPerformance(data.value, fractionDigits ?? 1);
                color = getPerformanceColor(valueDisplay);
                break;
            case EnumRankingType.SharpRatio:
                valueDisplay = formatNumber(data.value, fractionDigits ?? 1);
                break;
            case EnumRankingType.Reservations:
                valueDisplay = formatNumber(data.value);
                break;
        }
    }

    return (
        <Box
            {...sizePropsMap[size]}
            color={color}
            noOfLines={1}
            wordBreak="break-all"
            data-test-id={RANKING_VALUE_TEST_ID}
            fontWeight={fontWeight ?? "inherit"}
        >
            {value || valueDisplay}
        </Box>
    );
};
